<template>
    <v-container fluid class="">
        <confirmation-dialog :actions="actions" :dialog="dialogs.confirmdialog" @confirm="delete_employee" />
        <edit-profile-dialog
            :actions="actions"
            :dialog="dialogs.editprofiledialog"
            @updated="$emit('updated')"
            v-bind="{ profile: { ...employee, username: employee.user_name, password: '', confirmPassword: '' } }"
        />
        <v-row justify="center">
            <v-col cols="12">
                <v-card elevation="4" class="text-center pa-4">
                    <v-img
                        class="rounded-circle elevation-4 d-inline-block"
                        src="../../../../assets/profile.png"
                        width="128"
                        height="128"
                    />

                    <v-card-text class="text-center">
                        <v-row justify="center">
                            <h4 class="text-h4 mb-3 text--primary">
                                {{ employee.name }} - {{ employee.role.toUpperCase() }}
                            </h4>
                            <!-- Add Job Label next to employees name -->
                        </v-row>
                        <v-row justify="center">
                            <h6 class="text-h6 mb-2 text--secondary">#{{ employee.user_name }}</h6>
                        </v-row>

                        <v-row justify="center">
                            <v-col cols="6" md="4">
                                <v-btn
                                    class="mr-4"
                                    color="error"
                                    min-width="100"
                                    :loading="actions.loading"
                                    :disabled="actions.loading"
                                    @click="dialogs.confirmdialog.show = true"
                                    >{{$tr("Delete")}}</v-btn
                                >
                            </v-col>
                            <v-col cols="6" md="4" v-if="not_admin && not_sr">
                                <v-switch
                                    class="mt-0"
                                    color="primary"
                                    text
                                    :loading="actions.loading"
                                    v-model="employee.allowed"
                                    :disabled="actions.loading"
                                    @click="toggle_allowed"
                                    label="Task Creation"
                                ></v-switch>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-btn
                                    class="mr-0"
                                    color="primary"
                                    min-width="100"
                                    block
                                    :loading="actions.loading"
                                    :disabled="actions.loading"
                                    @click="dialogs.editprofiledialog.show = true"
                                    >{{$tr("Edit Profile")}}</v-btn
                                >
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Api from "@/api/api/index.js";
export default {
    data() {
        return {
            dialogs: {
                confirmdialog: {
                    text: {
                        title: "Delete This Employee Profile?",
                        text:
                            "are you sure you want to delete this employee profile, on confirmation the employee profile will be removed effective emmediatly.",
                        cancel: "Cancel",
                        yes: "Yes",
                    },
                    show: false,
                },
                editprofiledialog: {
                    show: false,
                },
            },
        };
    },
    computed: {
        not_admin() {
            return !this.employee?.role?.includes("admin");
        },
        not_sr() {
            return !this.employee?.role == "sr";
        },
    },
    methods: {
        async toggle_allowed() {
            this.actions.loading = true;
            try {
                console.log(this.employee.allowed);
                const response = await Api.post("employee/update/toggle_allowed", {
                    user_id: this.employee.user_id,
                    allowed: !this.employee.allowed,
                });
                console.log(response.data.result.employee.allowed);
                this.employee.allowed = response.data.result.employee.allowed;
                let notification = {
                    msg: "Midwake Bundle creation is toggled",
                    color: "success",
                };
                this.$store.dispatch("user/notify", notification);
            } catch (err) {
                let notification = {
                    msg: err.response?.data?.error?.msg || "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },
        async delete_employee() {
            this.actions.loading = true;
            try {
                const response = await Api.post("employee/update/delete", {
                    user_id: this.employee.user_id,
                });
                this.$emit("deleted");
                let notification = {
                    msg: "Employee Profiel Has Been Deleted",
                    color: "success",
                };
                this.$store.dispatch("user/notify", notification);
            } catch (err) {
                let notification = {
                    msg: err.response?.data?.error?.msg || "Error, please try again later",
                    color: "error",
                };
                this.$store.dispatch("user/notify", notification);
            }
            this.actions.loading = false;
        },
    },
    props: {
        employee: {
            type: Object,
            default: () => {
                return {};
            },
            required: true,
        },
        actions: {
            type: Object,
            required: true,
        },
    },
    components: {
        ConfirmationDialog: () =>
            import(
                /* webpackChunkName: "confirm_dialog" */
                "./confirm_dialog.vue"
            ),
        EditProfileDialog: () =>
            import(
                /* webpackChunkName: "edit-profile-dialog" */
                "./edit_profile.vue"
            ),
    },
};
</script>

<style></style>
